import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import { linkResolver } from "../../utils/linkResolver";
import get from "lodash.get";
import { Location as RouterLocation } from "@reach/router";

import Container from "../../styles/Container";
import ViewNext from "../../components/ViewNext";

import getDate from "../../utils/getDate";

import linkedinIcon from "../../images/linkedin-icon-dark.png";
import twitterIcon from "../../images/twitter-icon-dark.png";
import mailIcon from "../../images/mail-icon-dark.png";
import downloadIcon from "../../images/download-icon-dark.png";
import HelmetInfo from "../../components/HelmetInfo";

const Heading = styled.div`
  padding-top: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 50px;
  }
  h1 {
    font-family: "effra", sans-serif;
    font-size: 22px;
    line-height: 33px;
    font-weight: 700;
    text-align: center;
    @media (min-width: ${(p) => p.theme.breakpointMd}) {
      font-size: 36px;
      line-height: 54px;
    }
  }
`;

const Location = styled.p`
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 16px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 26px;
    line-height: 54px;
  }
`;

const DateContainer = styled.div`
  margin-bottom: 30px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  text-align: center;
  border-top: 1px solid ${(p) => p.theme.lightGrey};
  border-bottom: 1px solid ${(p) => p.theme.lightGrey};
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 20px;
    line-height: 25px;
  }
`;

const Article = styled.div`
  margin-top: 37px;
  margin-bottom: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 28px;
  }
  h1 {
    margin-top: 14px;
    margin-bottom: 10px;
    font-family: "effra", sans-serif;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    text-transform: uppercase;
  }
  a {
    text-decoration: underline;
  }
`;

const InfoBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 37px;
  padding-bottom: 37px;
  border-top: 1px solid ${(p) => p.theme.lightGrey};
  border-bottom: 1px solid ${(p) => p.theme.lightGrey};
  @media (min-width: 700px) {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    /* padding-top: 32px; */
    /* padding-bottom: 32px; */
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  a:hover {
    text-decoration: underline;
  }
`;
const InfoBox = styled.div`
  text-align: center;
  @media (min-width: 700px) {
    text-align: left;
  }
  :not(:last-child) {
    margin-bottom: 34px;
    @media (min-width: 700px) {
      margin-bottom: 0px;
      margin-right: 90px;
    }
  }
`;
const InfoBoxHeading = styled.h1`
  font-size: 24px;
  line-height: 32px;
`;
const InfoBoxContact = styled.div`
  font-size: 18px;
  line-height: 32px;
`;

const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 50px;
  }
  a:not(:last-child) {
    margin-right: 28px;
    @media (min-width: ${(p) => p.theme.breakpointMd}) {
      margin-right: 42px;
    }
  }
`;

const SocialMediaIcon = styled.img`
  width: 40px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: 56px;
  }
`;

const NewsArticle = ({ data }) => {
  const title = get(data, "prismicNewsArticle.data.title.richText");
  const date = get(data, "prismicNewsArticle.data.date");
  const location = get(data, "prismicNewsArticle.data.location");
  const text = get(data, "prismicNewsArticle.data.text");
  const uid = get(data, "prismicNewsArticle.uid");
  const downloadUrl = get(data, "prismicNewsArticle.data.download_link.url");

  const contacts = get(
    data,
    "allPrismicNewsIndex.edges[0].node.data.news_article_contact"
  );

  const { edges: allArticles, totalCount } = data.allPrismicNewsArticle;

  let nextUID = undefined;
  allArticles.forEach((article, index) => {
    if (article.node.uid === uid) {
      if (index + 1 < totalCount) {
        const nextArticle = allArticles[index + 1];
        nextUID = get(nextArticle, "node.uid");
      }
    }
  });

  return (
    <>
      <HelmetInfo page_data={data.prismicNewsArticle.data} />
      <Container>
        {title && <Heading>{RichText.render(title)}</Heading>}
        {location && <Location>{location}</Location>}
        {date && <DateContainer>Published on: {getDate(date)}</DateContainer>}
        <RouterLocation>
          {(props) => {
            const articleTitle = get(title, "[0].text");
            const href = get(props, "location.href");
            return (
              <SocialMediaContainer>
                <a
                  href={`https://www.linkedin.com/shareArticle?mini=true&url=${href}&title=${articleTitle}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SocialMediaIcon src={linkedinIcon} alt="Share on LinkedIn" />
                </a>
                <a
                  href={`https://twitter.com/intent/tweet?text=${articleTitle}&url=${href}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SocialMediaIcon src={twitterIcon} alt="Share on Twitter" />
                </a>
                <a
                  href={`mailto:?subject=${articleTitle}&body=${href}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SocialMediaIcon src={mailIcon} alt="Share via E-Mail" />
                </a>
                {downloadUrl && (
                  <a
                    href={downloadUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SocialMediaIcon
                      src={downloadIcon}
                      alt="Download article"
                    />
                  </a>
                )}
              </SocialMediaContainer>
            );
          }}
        </RouterLocation>
        {text && (
          <Article>
            <RichText render={text.richText} linkResolver={linkResolver} />
          </Article>
        )}
        <InfoBoxContainer>
          {contacts &&
            contacts.map((contact, index) => {
              const heading = get(contact, "contact_heading");
              const name = get(contact, "name");
              const company = get(contact, "company");
              const eMail = get(contact, "e_mail");
              const telephone = get(contact, "telephone");
              return (
                <InfoBox key={index}>
                  {heading && <InfoBoxHeading>{heading}</InfoBoxHeading>}
                  <InfoBoxContact>
                    {name && <div>{name}</div>}
                    {company && <div>{company}</div>}
                    {eMail && (
                      <div>
                        <a href={`mailto:${eMail}`}>{eMail}</a>
                      </div>
                    )}
                    {telephone && (
                      <div>
                        <a href={`tel:${telephone}`}>{telephone}</a>
                      </div>
                    )}
                  </InfoBoxContact>
                </InfoBox>
              );
            })}
        </InfoBoxContainer>
      </Container>
      {nextUID && (
        <ViewNext link={`/articles/${nextUID}`} linkText="Press Release" />
      )}
    </>
  );
};

export default NewsArticle;

export const query = graphql`
  query ($uid: String!) {
    prismicNewsArticle(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
          html
          richText
        }
        date
        location
        text {
          text
          html
          richText
        }
        download_link {
          link_type
          url
          uid
        }
      }
    }
    allPrismicNewsArticle(sort: { fields: data___date, order: DESC }) {
      totalCount
      edges {
        node {
          uid
        }
      }
    }
    allPrismicNewsIndex {
      edges {
        node {
          data {
            news_article_contact {
              contact_heading
              name
              company
              e_mail
              telephone
            }
          }
        }
      }
    }
  }
`;
